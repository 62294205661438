<template>
    <div class="panel">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">DEPOSIT RECONCILIATION</div>
            </div>
        </div>

        <VeeSavePane
            :cancelEnable="unsavedUpdates.length > 0"
            :saveEnable="unsavedUpdates.length > 0"
            :isEditing="unsavedUpdates.length > 0"
            @cancel-edit="fetchRecords()"
            @save-edit="saveAllUpdates()"
            >

        </VeeSavePane>

        <hr>

        <div class="columns">
            <div class="column is-4 has-text-left">
                <b-field>
                    <date-range-selector
                        :dateRange="salesDateRange"
                        :disabled="unsavedUpdates.length > 0"
                        @set-date-range="setDateRange"
                        >
                    </date-range-selector>                    
                </b-field>                    
            </div>

        </div>
        <div class="panel">
            <div class="panel-heading has-text-weight-bold">
                <div class="columns">

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">On Hand</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalOnhand < 0.00 }"> {{ totalOnhand | formatCurrency  }}</p>
                        </div>
                    </div>  

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">Disbursements</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalDepositAtm < 0.00 }"> {{ totalDepositAtm | formatCurrency  }}</p>
                        </div>
                    </div>  

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">General Deposit</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalDepositGeneral < 0.00 }"> {{ totalDepositGeneral | formatCurrency  }}</p>
                        </div>
                    </div> 

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">General Verified</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalDepositGeneral < 0.00 }"> {{ totalVerifiedGeneral | formatCurrency  }}</p>
                        </div>
                    </div> 

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">General Not Verified</p>
                        <p class="is-size-6" :class="{'has-text-danger': (totalDepositGeneral - totalVerifiedGeneral)  > 0.00 }">
                            {{ totalDepositGeneral - totalVerifiedGeneral  | formatCurrency }}
                        </p>
                        </div>
                    </div>

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">Other Deposit</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalDepositLottery < 0.00 }"> {{ totalDepositLottery | formatCurrency  }}</p>
                        </div>
                    </div> 

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">Other Verified</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalVerifiedLottery < 0.00 }"> {{ totalVerifiedLottery | formatCurrency  }}</p>
                        </div>
                    </div> 

                    <div class="column has-text-centered">
                        <div>
                            <p class="is-size-7">Lottery Not Verified</p>
                            <p class="is-size-6" :class="{'has-text-danger': (totalDepositLottery - totalVerifiedLottery)  > 0.00 }">
                                {{ totalDepositLottery - totalVerifiedLottery| formatCurrency }}
                            </p>
                        </div>
                    </div>

                    <div class="column has-text-centered">
                        <div>
                        <p class="is-size-7">Loaded In ATM</p>
                        <p class="is-size-6" :class="{'has-text-danger': totalLoadedInAtm < 0.00 }"> {{ totalLoadedInAtm | formatCurrency  }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="panel-block">
            <b-table class="control has-text-weight-semibold" 
                :data='dailyGroup'
                :paginated="isPaginated"
                :per-page="perPage"
                :striped="isStripped"
                :row-class="getRowClass"
                paginationPosition="bottom"
                sortIcon="arrow-up"
                :scrollable="isScrollable"
                sortIconSize="is-small"
                default-sort="salesDate"
                default-sort-direction="desc"
                :show-detail-icon="showDetailIcon"
                detailed
                detail-key="closingDate"
                >

                <template>
                    <b-table-column field="salesDate" label="Date" sortable sorticon v-slot="props">
                        {{ props.row.salesDate | formatDate}}
                    </b-table-column>

                    <b-table-column numeric field="cashActualOnhand" label="Actual On Hand" sortable sorticon v-slot="props">
                        {{ props.row.cashActualOnhand | formatCurrency}}
                    </b-table-column>

                    <b-table-column numeric field="bankDepositGeneral" label="Deposit General"  sortable sorticon v-slot="props">
                        <span  :class="{'has-text-danger': (props.row.bankDepositGeneral != props.row.generalAmountUpdated  && props.row.bankDepositGeneral) }">
                            {{ (props.row.bankDepositGeneral ? props.row.bankDepositGeneral : 0) | formatCurrency}}
                        </span>
                    </b-table-column>
 
                    <b-table-column numeric field="generalAmountUpdated" label="Actual General" sortable sorticon v-slot="props">
                        <b-field>
                            
                            <VeeInput
                                type="currency" 
                                v-model="props.row.generalAmountUpdated"
                                name="generalAmountUpdated"
                                :value="props.row.generalAmountUpdated"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="isEditable"
                                @input="updatedDailyRow(props.row,'amount1')"
                                />
                        </b-field>
                        
                    </b-table-column>

                    <b-table-column centered field="generalDepositAccountVerifiedDate" label="Bank Credit Date General" sortable sorticon v-slot="props">
                            <b-field>
                            <b-datepicker 
                                :mobile-native="isMobileNativeDatePicker"
                                :min-date="minClearDate(props.row)"
                                :max-date="new Date()"
                                position="is-top-left"
                                custom-class="has-text-centered deposit-input"
                                placeholder="Actual Date"
                                editable
                                :append-to-body="datePickerAppendToBody"
                                @input="updatedDailyRow(props.row,'date1')"
                                @focus="$event.target.select()"
                                v-model="props.row.generalDateUpdated">
                            </b-datepicker>
                            <!-- :disabled="amountZero(props.row, 'amount1')" -->

                        </b-field>
                    </b-table-column>

                    <b-table-column numeric field="bankDepositLottery" label="Deposit Other" sortable sorticon v-slot="props">
                        <span  :class="{'has-text-danger': (props.row.bankDepositLottery != props.row.lotteryAmountUpdated && props.row.bankDepositLottery) }">
                            {{ (props.row.bankDepositLottery ? props.row.bankDepositLottery : 0) | formatCurrency}}
                        </span>
                    </b-table-column>


                    <b-table-column numeric field="lotteryAccountVerifiedAmount" label="Actual Other"  sortable sorticon v-slot="props">
                        <b-field>
                            
                            <VeeInput
                                type="currency" 
                                v-model="props.row.lotteryAmountUpdated"
                                name="lotteryAmountUpdated"
                                :value="props.row.lotteryAmountUpdated"
                                :countryCurrencyCode="countryCurrencyCode"
                                :editable="isEditable"
                                @input="updatedDailyRow(props.row,'amount2')"
                                />
                        </b-field>
                    </b-table-column>

                    <b-table-column centered field="generalDepositAccountVerifiedDate" label="Bank Credit Date Other" sortable sorticon v-slot="props">
                        <b-field>
                            <b-datepicker 
                                :mobile-native="isMobileNativeDatePicker"
                                :min-date="minClearDate(props.row)"
                                :max-date="new Date()"
                                position="is-top-left"
                                custom-class="has-text-centered deposit-input"
                                placeholder="Actual Date" 
                                editable
                                :append-to-body="datePickerAppendToBody"
                                @focus="$event.target.select()"
                                @input="updatedDailyRow(props.row,'date2')"                        
                                v-model="props.row.lotteryDateUpdated">
                            </b-datepicker>                            
                            <!-- :disabled="amountZero(props.row, 'amount2')" -->
                        </b-field>
                    </b-table-column>

                    <b-table-column numeric field="bankDepositAtm" label="Cash Disbrs." sortable sorticon v-slot="props">
                        {{ props.row.bankDepositAtm | formatCurrency}}
                    </b-table-column>
                    <b-table-column numeric field="cashLoadedInAtm" label="Loaded ATM." sortable sorticon v-slot="props">
                        {{ props.row.cashLoadedInAtm | formatCurrency}}
                    </b-table-column>

                </template>
                <template slot="detail" slot-scope="props">
                    <b-table 
                        :data="props.row.shifts" 
                        :show-header="showDetailHeader"
                        :row-class="getRowClass"
                        :scrollable="isScrollable"
                        >
                        <template>
            
                            <b-table-column field="salesDate" label="Date" sortable sorticon v-slot="props">
                                {{ props.row.salesDate | formatDate}}
                            </b-table-column>
                            
                            <b-table-column numeric field="cashActualOnhand" label="Actual On Hand" sortable sorticon v-slot="props">
                                {{ props.row.cashActualOnhand | formatCurrency}}
                            </b-table-column>

                            <b-table-column field="bankDepositGeneral" label="Account 1 Deposit"  sortable sorticon v-slot="props">
                                {{ props.row.depositGeneralNotes }}
                            </b-table-column>

                            <b-table-column numeric field="bankDepositGeneral" label="Account 1 Deposit"  sortable sorticon v-slot="props">
                                <span  :class="{'has-text-danger': (props.row.bankDepositGeneral != props.row.generalAmountUpdated) }">
                                    {{ (props.row.bankDepositGeneral ? props.row.bankDepositGeneral : 0) | formatCurrency}}
                                </span>
                            </b-table-column>
        
                            <b-table-column numeric field="generalAmountUpdated" label="Account 1 Bank Amount" sortable sorticon v-slot="props" cell-class="has-background-white-ter">
                                
                                {{ props.row.generalAmountUpdated | formatCurrency }}
                                
                            </b-table-column>

                            <b-table-column centered field="generalDepositAccountVerifiedDate" label="Account 1 Bank Date" sortable sorticon v-slot="props"  cell-class="has-background-white-ter">
                                    
                                <span v-if="props.row.generalDateUpdated">
                                    {{  props.row.generalDateUpdated | formatDate }}
                                </span>
                                <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                            </b-table-column>

                            <b-table-column field="bankDepositGeneral" label="Account 1 Deposit"  sortable sorticon v-slot="props">
                                {{ props.row.depositLotteryNotes }}
                            </b-table-column>

                            <b-table-column numeric field="bankDepositLottery" label="Account 2 Deposit" sortable sorticon v-slot="props">
                                <span  :class="{'has-text-danger': (props.row.bankDepositLottery != props.row.lotteryAmountUpdated && props.row.bankDepositLottery) }">
                                    {{ (props.row.bankDepositLottery ? props.row.bankDepositLottery : 0) | formatCurrency}}
                                </span>
                            </b-table-column>


                            <b-table-column numeric field="lotteryAccountVerifiedAmount" label="Account 2 Bank Amount"  sortable sorticon v-slot="props"  cell-class="has-background-white-ter">
         
                                {{ props.row.lotteryAmountUpdated |formatCurrency }}
                            </b-table-column>

                            <b-table-column centered field="generalDepositAccountVerifiedDate" label="Account 2 Bank Date" sortable sorticon v-slot="props" cell-class="has-background-white-ter">

                                <span v-if="props.row.lotteryDateUpdated">
                                    {{  props.row.lotteryDateUpdated | formatDate }}
                                </span>
                                <span v-else>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                
                            </b-table-column>

                            <b-table-column numeric field="bankDepositGeneral" label="Account 1 Deposit"  sortable sorticon v-slot="props">
                                {{ props.row.depositAtmNotes}}
                            </b-table-column>

                            <b-table-column numeric field="bankDepositAtm" label="Other Cash Disbrs." sortable sorticon v-slot="props">
                                {{ props.row.bankDepositAtm | formatCurrency}}
                            </b-table-column>
                            <b-table-column numeric field="bankDepositAtm" label="Other Cash Disbrs." sortable sorticon v-slot="props">
                                {{ props.row.cashLoadedInAtm | formatCurrency}}
                            </b-table-column> 
                            <b-table-column label="Shift" v-slot="props" >
                                <b-tooltip position="is-left" animated multilined>
                                    {{ props.index + 1 }} 🕘 
                                    <template v-slot:content>
                                        <div class="panel">
                                            <p class="is-4 has-text-weight-bold">SHIFT {{ props.index + 1 }}</p>
                                            <p><b>Start</b>: {{ props.row.periodBeginDate | formatDateTime }}</p>
                                            <p><b>&nbsp;&nbsp;End</b>: {{ props.row.periodEndDate | formatDateTime }}</p>
                                        </div>
                                    </template>
                                </b-tooltip>
                            </b-table-column>
                           
                        </template>
                    </b-table>
                </template>


                <template slot="empty">
                    <data-loading 
                        :isLoading="isLoading" 
                        :isFullPage="isFullPage"> 
                    </data-loading>
                </template>

            </b-table>

        </div>
    </div>
</template>

<script>
import axios from 'axios'
import DateRangeSelector from '../../../components/app/DateRangeSelector.vue'
import UserStore from '../../../store/UserStore'
import moment from 'moment'
import DataLoading from '../../../components/app/DataLoading.vue'
import VeeInput from '../../../components/app/VeeInput.vue'
import VeeSavePane from '../../../components/app/VeeSavePane.vue'

export default {

    beforeRouteLeave (to, from, next) {
        
        if (this.unsavedUpdates.length > 0) {
            this.$buefy.dialog.confirm({
                title: 'Leaving without saving!',
                message: 'Are you sure you want to discard all changes and leave?',
                confirmText: 'Discard Changes',
                type: 'is-danger',
                hasIcon: true,
                onConfirm: () => next()
            })
        } else {
            next()
        }
    },

    components: {
        DateRangeSelector,
        DataLoading,
        VeeInput,
        VeeSavePane
    },

    data() {
        return {
            datePickerAppendToBody: true,
            isMobileNativeDatePicker: false,
            showDetailHeader: false,
            isScrollable: true,
            maxClearDate: new Date(),
            showNumberControls: false,
            showDetailIcon: true,
            salesDateRange: [moment().startOf('month').toDate(), new Date],
            isPaginated: true,
            page: 0,
            perPage: 25,
            isFullPage: true,
            isLoading: true,
            sortField: 'sale.closingDate',
            sortOrder: 'desc',
            isStripped: true,
            user: UserStore.getters.getUser,
            salesRecords: [],
            isShowEditDialog: false,
            isShowPDFDialog: false,
            unsavedUpdates: [],
            dailyGroup: [],
            dailyShifts: [],
            isEditable: true,
            isShiftRowEditable: false,
            countryCurrencyCode: UserStore.getters.getCountryCurrencyCode,
            tableNumericFields: [
                "netSales",
                "merchSales",
                "fuelSales",
                "cashOverShort",
                "cashAmount",
                "totalCashIncome",
                "totalCashExpense",
                "cashActualOnhand",
                "bankDepositGeneral",
                "bankDepositLottery",
                "bankDepositAtm",
                "cashLoadedInAtm",
                "safedropAmount",
                "safedropCashAmount",
                "cashRefundAmount",
                "onlineLottoSales",
                "onlineLottoPayout",
                "instantLottoSales",
                "instantLottoPayout",
                "netLottery",
                "lotteryMachineWithdraw",
                "onlineLotteryCanceled",

            ],

        }
    },

    methods: {
        
        setDateRange(dateRange) {
            this.salesDateRange = [dateRange[0], dateRange[1]]
        },

        async fetchRecords() {

            this.isLoading = true
            this.dailyGroup = []
            this.salesRecords = []
            this.unsavedUpdates = []

            var startDate = this.salesDateRange[0].toISOString().split('T')[0]
            var endDate = this.salesDateRange[1].toISOString().split('T')[0]

            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' 
                + UserStore.getters.getActiveStore.storeId + "/sales/shifts/net?size=50"  + "&fromDate="+startDate+"&toDate="+endDate

            axios.get(url,
                {
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken,
                        'correlation-id': this.$uuid.v1()
                    },                   
                })
                .then( (response) => {
                    // this.salesRecords = response.data.data.filter(item => 
                    //     item.bankDepositGeneral ||
                    //     item.bankDepositLottery 
                    // )
                    this.salesRecords = response.data.data

                    this.dailyShifts = this.salesRecords.sort(function(a,b){
                            return new Date(a.periodBeginDate) - new Date(b.periodBeginDate)
                            })

                    this.dailyShifts.forEach((item,index) => {
                        item.rowId = index+1
                        item.generalAmountUpdated = item.generalDepositAccountVerifiedAmount
                        item.generalDateUpdated = this.stringToDate(item.generalDepositAccountVerifiedDate)
                        item.lotteryAmountUpdated =  item.lotteryAccountVerifiedAmount 
                        item.lotteryDateUpdated = this.stringToDate(item.lotteryAccountVerifiedDate)                             
                    })

                    this.tableNumericFields.forEach((element) => {
                        if (this.dailyShifts[element] === null) {
                            this.dailyShifts[element] = 0
                        }
                    })

                    this.groupedByDate()
                    
                    this.isLoading = false
                })
                .catch( (err) => {

                    this.$buefy.toast.open({
                        message: 'No Audit Records found for the period ' + err ,
                        type: 'is-warning'
                    })

                    this.salesRecords = []
                    
                    this.isLoading = false
                })


        },

        groupedByDate() {

            var sortedDailyShifts = this.salesRecords.sort((a,b) =>  b.periodBeginDate-a.periodBeginDate )

            sortedDailyShifts.forEach((item, index) => {
                item.shiftOrder = index
                var prevCashOnHand = 0
                if (index > 0) {
                    prevCashOnHand = sortedDailyShifts[index-1].netCashOnHand
                }
                item.netCashOnHand = prevCashOnHand +
                                item.safedropCashAmount +
                                item.cashActualOnhand -
                                item.bankDepositGeneral -
                                item.bankDepositLottery -
                                item.bankDepositAtm - 
                                item.cashLoadedInAtm
            })
            
            this.validSales =  sortedDailyShifts.filter(e => e.netSales !== null)
            this.dailyGroup = Object.values(this.validSales.reduce((a,{closingDate, ...props}) => {
                if(!a[closingDate]) {
                    a[closingDate]  = Object.assign({}, {closingDate: props,shifts : [props]});
                    a[closingDate].netSales = 0.00
                    a[closingDate].merchSales = 0.00
                    a[closingDate].fuelSales = 0.00
                    a[closingDate].cashAmount = 0.00
                    a[closingDate].totalCashIncome = 0.00
                    a[closingDate].totalCashExpense = 0.00
                    a[closingDate].cashActualOnhand = 0.00
                    a[closingDate].cashOverShort = 0.00
                    a[closingDate].bankDepositGeneral = 0.00
                    a[closingDate].bankDepositLottery = 0.00
                    a[closingDate].bankDepositAtm = 0.00
                    a[closingDate].cashLoadedInAtm = 0.00
                    a[closingDate].safedropAmount = 0.00
                    a[closingDate].safedropCashAmount = 0.00
                    a[closingDate].cashRefundAmount = 0.00
                    a[closingDate].netCashOnHand = 0.00
                    a[closingDate].netLottery = 0.00
                    a[closingDate].instantLottoSales = 0.00
                    a[closingDate].instantLottoPayout = 0.00
                    a[closingDate].onlineLottoSales = 0.00
                    a[closingDate].onlineLotteryCanceled = 0.00
                    a[closingDate].onlineLottoPayout = 0.00
                    a[closingDate].lotteryMachineWithdraw = 0.00
                    a[closingDate].generalAmountUpdated = (props.generalDepositAccountVerifiedAmount ? props.generalDepositAccountVerifiedAmount : 0)
                    a[closingDate].generalDateUpdated = (props.generalDepositAccountVerifiedDate ? moment(props.generalDepositAccountVerifiedDate).toDate() : null)
                    a[closingDate].lotteryAmountUpdated = (props.lotteryAccountVerifiedAmount ? props.lotteryAccountVerifiedAmount : 0)
                    a[closingDate].lotteryDateUpdated = (props.lotteryAccountVerifiedDate ? moment(props.lotteryAccountVerifiedDate).toDate() : null) 
                    a[closingDate].cashActualOnhand = props.cashActualOnhand
                    a[closingDate].bankDepositGeneral = props.bankDepositGeneral
                    a[closingDate].bankDepositLottery = props.bankDepositLottery
                    a[closingDate].bankDepositAtm = props.bankDepositAtm
                    a[closingDate].cashLoadedInAtm = props.cashLoadedInAtm
                    a[closingDate].salesDate = String(closingDate[1]).padStart(2, '0')+'/'+String(closingDate[2]).padStart(2, '0')+'/'+closingDate[0]
                    a[closingDate].shifts[0].salesDate = a[closingDate].salesDate
                } else {
                    if (props.netSales != null) {
                        props.salesDate = String(closingDate[1]).padStart(2, '0')+'/'+String(closingDate[2]).padStart(2, '0')+'/'+closingDate[0]
                        a[closingDate].shifts.push(props);
                        a[closingDate].generalAmountUpdated += props.generalDepositAccountVerifiedAmount
                        a[closingDate].lotteryAmountUpdated += props.lotteryAccountVerifiedAmount
                        if (a[closingDate].generalDateUpdated == null) {
                            a[closingDate].generalDateUpdated = (props.generalDepositAccountVerifiedDate ? moment(props.generalDepositAccountVerifiedDate).toDate() : null)
                        }
                        if (!a[closingDate].lotteryDateUpdated) {
                            a[closingDate].lotteryDateUpdated = (props.lotteryAccountVerifiedDate ? moment(props.lotteryAccountVerifiedDate).toDate() : null)
                        }
                        a[closingDate].cashActualOnhand += props.cashActualOnhand
                        a[closingDate].bankDepositGeneral += props.bankDepositGeneral
                        a[closingDate].bankDepositLottery += props.bankDepositLottery
                        a[closingDate].bankDepositAtm += props.bankDepositAtm
                        a[closingDate].cashLoadedInAtm += props.cashLoadedInAtm
                    }
                }

                return a;
            },{}));
            
            this.dailyGroup.forEach((item, index) => {
                item.shiftOrder = index
                var prevCashOnHand = 0
                if (index > 0) {
                    prevCashOnHand = this.dailyGroup[index-1].netCashOnHand
                }
                item.netCashOnHand = prevCashOnHand +
                                item.safedropCashAmount +
                                item.cashActualOnhand -
                                item.bankDepositGeneral -
                                item.bankDepositLottery -
                                item.bankDepositAtm
            })
        },

        async saveRecord(row) {
            // this.isLoading = true
            const url = process.env.VUE_APP_API_ROOT_URL + '/stores/' + this.user.activeStore 
                + '/sales/shifts/' + row.id + '/depositverification' 

            var verificationData = {
                        "generalDepositAccountVerifiedAmount": row.generalAmountUpdated,
                        "generalDepositAccountVerifiedDate":this.dateToString(row.generalDateUpdated),
                        "lotteryAccountVerifiedAmount": row.lotteryAmountUpdated,
                        "lotteryAccountVerifiedDate": this.dateToString(row.lotteryDateUpdated)
                    }

            await axios.put(url,
                verificationData,
                {   
                    headers: {
                        'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                        'Refresh-Token': UserStore.getters.getRefreshToken
                    },                   
                })
                .then( (response) => {
                    if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                    if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        
                })
                .catch(() => {
                    this.$buefy.toast.open({
                        message: "Failed to save Deposit Verification",
                                type: 'is-danger'
                    }) 
                    this.isLoading = false
                }) 


        },

        async togglePDFDialog() {
            this.isShowPDFDialog = !this.isShowPDFDialog
        },

        async saveAllUpdates() {
            var dataOK = true
            var badDataMessage = ""
            this.isLoading = true

            this.unsavedUpdates.forEach((item) => {   
                if (item.generalAmountUpdated) {
                    if (isNaN(item.generalAmountUpdated)) {
                        dataOK = false
                        badDataMessage = item.salesDate + " Account 1 <br>Amount <b>must be a number</b>"
                    }
                    if (this.dateToString(item.generalDateUpdated) == null) {
                        dataOK = false
                        badDataMessage = item.salesDate + " - " + item.generalAmountUpdated + " Account 1 <br>Date <b>cannot be Empty</b>"
                    }
                }
                if (item.lotteryAmountUpdated) {
                    if (isNaN(item.lotteryAmountUpdated)) {
                        dataOK = false
                        badDataMessage = item.salesDate + " Account 2 <br>Amount <b>must be a number</b>"
                    }
                    if (this.dateToString(item.lotteryDateUpdated) == null) {
                        dataOK = false
                        badDataMessage = item.salesDate + " Account 2 <br>Date <b>cannot be Empty</b>"
                    }
                }          
                if (item.generalDateUpdated) {
                    // if (item.generalAmountUpdated == null || item.generalAmountUpdated == "") {
                    //     dataOK = false
                    //     badDataMessage = item.salesDate + " Account 1 <br>Amount <b>cannot be Empty</b>"
                    // }
                    if (item.generalDateUpdated > new Date()) {
                        dataOK = false
                        badDataMessage =  item.salesDate + " Account 1 <br>Date <b>cannot be after Current Date</b>"
                    }
                    if (item.generalDateUpdated < this.minClearDate(item)) {
                        dataOK = false
                        badDataMessage = item.salesDate + " Account 1 <br>Date <br>cannot be before Shift Date</b>"
                    }
                }
                if (item.lotteryDateUpdated) {
                    // if (item.lotteryAmountUpdated == null || item.lotteryAmountUpdated == "") {
                    //     dataOK = false
                    //     badDataMessage = item.salesDate + " Account 2 <br>Amount <b>cannot be Empty</b>"
                    // }
                    if (item.lotteryDateUpdated > new Date()) {
                        dataOK = false
                        badDataMessage = item.salesDate + " Account 2 <br>Date <b>cannot be after Current Date</b>"
                    }
                    if (item.lotteryDateUpdated < this.minClearDate(item)) {
                        dataOK = false
                        badDataMessage = item.salesDate + " Account 2 <br>Date <b>cannot be before Shift Date</b>"
                    }                    
                }
            })

            if (dataOK) {
                for (const item of this.unsavedUpdates) {
                    await this.saveRecord(item)
                }
                this.$buefy.toast.open({
                    message: "Deposit Verification Saved for " + this.unsavedUpdates.length + " record(s)",
                            type: 'is-success',
                            duration: 5000
                }) 
                this.unsavedUpdates = []
                // this.dailyGroup = []
                this.isLoading = false
                await this.fetchRecords()
                // this.$router.go(0)
                // this.salesDateRange = curDateRange

            } else {

                this.$buefy.dialog.alert({
                    title: 'Invalid/Incomplete Entry',
                    message: badDataMessage + ". <br>No changes saved. <p>Please correct the error and try again.",
                    type: 'is-danger',
                    hasIcon: true,
                    icon: 'times-circle',
                    iconPack: 'fa',
                    ariaRole: 'alertdialog',
                    ariaModal: true
                })
                this.isLoading = false
            }
        },

        dateToString(date) {
            if (date != null) {
                return (date ? moment(date).toISOString().split('T')[0] : null)
            }
            return date
        },

        stringToDate(dateString) {
            return (dateString ? moment(dateString).toDate() : null)
        },

        removeNonNumeric(numberWithString) {

         return Number(numberWithString.toString().replace(/[^\d.-]/g,''))
        },

        updatedDailyRow(row,field) {
            var shiftShare = 0
            var notSharedAmount1 = this.removeNonNumeric(row.generalAmountUpdated)
            var notSharedAmount2 = this.removeNonNumeric(row.lotteryAmountUpdated)
            row.shifts.forEach((shift, index) => {
                switch (field) {
                    case "amount1":
                        if (row.shifts.length > 1) {
                            if (shift.bankDepositGeneral <= notSharedAmount1) {
                                if (row.shifts.length <= index+1 ) {
                                    shiftShare = notSharedAmount1
                                } else {
                                    shiftShare = shift.bankDepositGeneral
                                }
                                notSharedAmount1 -= shiftShare
                                
                            } else {
                                shiftShare = notSharedAmount1
                                notSharedAmount1 = 0
                            }
                        } else {
                            shiftShare = row.generalAmountUpdated
                        }
                        // if (shiftShare === 0) {
                        //     shift.generalDateUpdated = null
                        // } else {
                            shift.generalDateUpdated = row.generalDateUpdated                            
                        // }
                        shift.generalAmountUpdated = shiftShare
                        break

                    case "amount2":
                        if (row.shifts.length > 1) {
                            if (shift.bankDepositLottery <= notSharedAmount2) {
                                if (row.shifts.length <= index+1 ) {
                                    shiftShare = notSharedAmount2
                                } else {
                                    shiftShare = shift.bankDepositLottery
                                }
                                notSharedAmount2 -= shiftShare
                                
                            } else {
                                shiftShare = notSharedAmount2
                                notSharedAmount2 = 0
                            }
                        } else {
                            shiftShare = row.lotteryAmountUpdated
                        }
                        // if (shiftShare === 0) {
                        //     shift.lotteryDateUpdated = null
                        // } else {
                            shift.lotteryDateUpdated = row.generalDateUpdated                            
                        // }
                        shift.lotteryAmountUpdated = shiftShare
                        break

                    case "date1":
                        // if (shift.generalAmountUpdated > 0) {
                            shift.generalDateUpdated = row.generalDateUpdated
                        // } else {
                        //     shift.generalDateUpdated = null
                        // }
                        break

                    case "date2":
                        // if (shift.lotteryAmountUpdated > 0) {
                            shift.lotteryDateUpdated = row.lotteryDateUpdated
                        // } else {
                        //     shift.lotteryDateUpdated = null
                        // }
                        break
                        
                }
                
            })
            
            this.filterUnsavedUpdates()

        },

        filterUnsavedUpdates() {

            this.unsavedUpdates = []

            // this.dailyGroup.forEach((group) => {
            //     var dayShifts = group.shifts.filter(item => (
            //         item.generalAmountUpdated != item.generalDepositAccountVerifiedAmount ||
            //         this.dateToString(item.generalDateUpdated) != item.generalDepositAccountVerifiedDate ||
            //         item.lotteryAmountUpdated != item.lotteryAccountVerifiedAmount ||
            //         this.dateToString(item.lotteryDateUpdated) != item.lotteryAccountVerifiedDate
            //     )) 
            //     this.unsavedUpdates = [...this.unsavedUpdates, ...dayShifts]
            
            // })

            this.dailyGroup.forEach((group) => {
                var dayShifts = group.shifts.filter(item => (
                    this.dateToString(item.generalDateUpdated) != item.generalDepositAccountVerifiedDate ||
                    this.dateToString(item.lotteryDateUpdated) != item.lotteryAccountVerifiedDate
                )) 
                this.unsavedUpdates = [...this.unsavedUpdates, ...dayShifts]
            
            })

        },

        getRowClass(row) {

            var warnClass = "has-background-warning-light"
            var defClass = "has-background-default"

            if (row.bankDepositGeneral || row.bankDepositLottery) {
                if (row.generalAmountUpdated) {
                    if (row.generalAmountUpdated != row.bankDepositGeneral) {
                        return warnClass
                    }
                    if (row.generalDateUpdated === null) {
                        return warnClass
                    }
                } else {
                    if (row.bankDepositGeneral) {
                        return warnClass
                    }
                }
                if (row.lotteryAmountUpdated) {
                    if (row.lotteryAmountUpdated != row.bankDepositLottery) {
                        return warnClass
                    }
                    if (row.lotteryDateUpdated === null) {
                        return warnClass
                    }
                } else {
                    if (row.bankDepositLottery) {
                        return warnClass
                    }
                }               
            }
            return defClass
        },

        beforeLeavingPage(event) {
            
            if (this.unsavedUpdates.length > 0) {          
                event.preventDefault()
                event.returnValue = ""
            }
    
        }, 
        
        minClearDate(row) {
            var minDate =  moment(row.salesDate).toDate()
            return minDate
        },


        amountZero(row,field) {
            if (field == "amount1") {
                if (row.generalAmountUpdated == null || row.generalAmountUpdated == 0) {
                    row.generalDateUpdated = null
                    row.shifts.forEach((item) => {
                        item.generalDateUpdated = null
                    })
                    return true
                } else {
                    return false
                }
            } 
            if (field == "amount2") {
                if (row.lotteryAmountUpdated == null || row.lotteryAmountUpdated == 0) {
                    row.lotteryDateUpdated = null
                    row.shifts.forEach((item) => {
                        item.lotteryDateUpdated = null
                    })
                    return true
                } else {
                    return false
                }
            }
            return true
        },

    },

    watch: {
        salesDateRange() {
            this.unsavedUpdates = []
            this.fetchRecords()
        }
    },

    computed: {
        totalOnhand() {
            return this.sumArrayProp(this.salesRecords, "cashActualOnhand")
        },
        totalDepositAtm() {
            return this.sumArrayProp(this.salesRecords, "bankDepositAtm")
        },    
        totalLoadedInAtm() {
            return this.sumArrayProp(this.salesRecords, "cashLoadedInAtm")
        },  
        totalDepositGeneral() {
            return this.sumArrayProp(this.salesRecords, "bankDepositGeneral")
        },
        totalVerifiedGeneral() {
            return this.sumArrayProp(this.salesRecords, "generalDepositAccountVerifiedAmount")
        },
        totalDepositLottery() {
            return this.sumArrayProp(this.salesRecords, "bankDepositLottery")
        },
        totalVerifiedLottery() {
            return this.sumArrayProp(this.salesRecords, "lotteryAccountVerifiedAmount")
        },

    },

    mounted() {
        this.fetchRecords()
    }
    
}
</script>

<style>
.deposit-input  {

    background-color: rgb(250, 249, 246);
    border-radius: 0px;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 110px;
    border-color: rgb(37, 37, 36);
    border-width: 1px;
    height: 30px;
}

.deposit-input:focus  {
    background-color: #e6f1f9;
    border-color: #9ecaed;
    box-shadow: #9ecaed;


}

.red-font {
    color: red;
}

.fees-input input[type=number]  {

    background-color: white;
    text-align: center;
    font-weight: 900;
    font-size-adjust: calc(500%);
    color: rgb(0, 0, 0);
    width: 100px;
    border-color: gold;
    border-width: 2px;
}

.fees-input input[type=number][disabled] {
    background-color: whitesmoke;
    font-weight:normal;
    color: gray;
    border-color: gray;
    border-width: 1px;
}

.save-buttons-overlay {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 10;
  top: 50px;
  /* padding-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px; */
}

/* .modal .animation-content .modal-card {
  overflow: visible !important;
} */


</style>